<template>
  <div>
    
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row justify-content-center text-center mb-6">
                <div class="col">
                    <h5>PETA SEBARAN PASIEN</h5>
                </div>
            </div>
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  props: {
    items: Array,
  },
  components: {
    Card,
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "list_number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Wilayah",
          sortable: true,
        },
        {
          key: "total_patient",
          label: "Jumlah Pasien",
          sortable: true,
        },
        {
          key: "total_male",
          label: "Laki-laki",
          sortable: true,
        },
        {
          key: "total_female",
          label: "Perempuan",
          sortable: true,
        },
      ],
    };
  },

  methods: {},

  mounted() {},
};
</script>
